import { collection, query, where, getCountFromServer, doc } from "firebase/firestore";

import Timestamp from "utils/Timestamp";
import { games, db } from "./firebase";

const getGames = async () => {
  var results = [];
  await games
    .orderBy("timestamp", "desc")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var game = {
          id: doc.id,
          ...doc.data(),
        };
        results.push(game);
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return results;
};

// initialize a new game based on the most recent
// published game on the networks's database
const getGame = async (route) => {
  console.log("fetching game");
  // Get all active games
  const activeGamesSnapshot = await db.collection("games").where("status", "==", "active").get();

  const activeGames = activeGamesSnapshot.docs.map((doc) => {
    const data = doc.data();
    return { id: doc.id, ...data };
  });

  if (!activeGames || activeGames.length === 0) {
    const fallback = await getGames();
    console.warn("No active games, falling back ", fallback[0]);
    return fallback[0];
  }

  // Fetch submissions for all active games in parallel
  const submissionsCounts = await Promise.all(
    activeGames.map(async (game) => {
      const collectionRef = collection(db, "submissions");
      const gameDocRef = doc(db, "games", game.id);
      const q = query(collectionRef, where("game", "==", gameDocRef));
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;
      // console.log(`Game ID: ${game.id}, Submission Count: ${count}`);
      return count;
    })
  );

  // Find the index of the game with the lowest number of submissions
  const minSubmissionsIndex = submissionsCounts.indexOf(Math.min(...submissionsCounts));

  const routedGame = activeGames.find((e) => e.route === route);

  if (!routedGame && minSubmissionsIndex === -1) {
    throw new Error("No game found");
  }

  // Return the game with the lowest number of submissions
  return route?.length > 0 && routedGame ? routedGame : activeGames[minSubmissionsIndex];
};

// initialize a new game based on the most recent
// published game on the networks's database
const createGame = (params) => {
  let result = games
    .add({
      name: params.name,
      rounds: params.configs,
      questions: params.questions,
      status: params.status,
      route: params.name.toLowerCase().replace(" ", ""),
      timestamp: Timestamp(),
    })
    .then(() => {
      console.log("Document successfully written!");
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  return result;
};

// destroy all existing documents within the game collection
const nukeGames = (confirm) => {
  if (confirm()) {
    let batch = db.batch();
    games
      .get()
      .then(function (snapshot) {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .catch((e) => alert(e));
  }
};

// updates a model with new parameters
const updateGame = async ({ id, name, questions, status, configs }) => {
  return await games.doc(id).update({
    name: name,
    rounds: configs,
    questions: questions,
    status: status,
    route: name.toLowerCase().replace(" ", ""),
    timestamp: Timestamp(),
  });
};

// Deletes a game by id
const deleteGame = (id) => {
  let result = games
    .doc(id)
    .delete()
    .then(console.log("Document successfully deleted model!"))
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  return result;
};

const GameService = {
  getGames,
  getGame,
  createGame,
  nukeGames,
  updateGame,
  deleteGame,
};

export default GameService;
